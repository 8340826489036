<template>
    <div class="trainingEnterprise">
        <!-- 顶部banner图 -->
        <div class="top_banner">
            <el-carousel :interval="5000" >
                <el-carousel-item v-for="(item,idx) in enterpriseBannerList" :key="idx">
                    <img :src="item.img" alt="">
                    <div class="banner_title">
                        <div class="title_text">{{item.title}}</div>
                        <div class="title_line"></div>
                        <div class="title_tips">{{item.tips}}</div>
                    </div>
                    <!-- 轮播图查看详情 -->
                    <div class="show_detail" @click="showMoreCourse">
                        查看详情
                        <img src="@/assets/images/school/show_detail.png" alt="" srcset="">
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 国家职业预约咨询 -->
        <div class="appointment">
            <div class=" flex JustifyContentSB AlignItemsC ">
                <div class="left flex">
                    <div class="b_title">培考通 · </div>
                    <div class="occupation">企业补贴服务</div>
                </div>
                <div class="right flex JustifyContentC AlignItemsC" @click='appointenting'>
                    <img src="@/assets/images/school/appointent@2x.png" alt="">
                    预约咨询
                </div>
            </div>
        </div>
        <!-- 行业动态 -->
        <dynamic :dynamicList= 'dynamicList' :showMore= 'showMore'></dynamic>
        <!-- 合作企业 -->
        <div class="partnerSchool ">
             <div class="dy_title flex JustifyContentSB">
                <div class="titte_before">合作企业</div>
            </div>
            <div class="partnerSchool_img">
                <img src="@/assets/images/school/enterprise.png" alt="" srcset="">
            </div>
        </div>
        <!-- 分类列表 -->
        <category :categoryList="firstCategoryList" :courseData='courseData'></category>
        <!-- 课程视频列表 -->
        <videoModule :iframeForm='iframeForm' :iframeList='iframeList'></videoModule>
         <!-- 预约咨询弹窗 -->
        <appointmentCon @closeDialog='clsoeDialog' :appointmentTitle='appointmentTitle' :appointmentForm = 'appointmentForm' :appointmentVisiable='appointmentVisiable'></appointmentCon>
    </div>
</template>

<script>
import dynamic from '../../components/trainingComponent/dynamic.vue';
import appointmentCon from '../../components/appointmentCon.vue'
import category from '../../components/trainingComponent/category.vue';
import videoModule from '../../components/trainingComponent/videoModule.vue'
import courseClass from '../../assets/jsons/courseClass.json'
import courseData from '../../assets/jsons/courseData.json'
export default {
    components:{
        dynamic,
        appointmentCon,
        category,
        videoModule
    },
    data(){
        return{
            // 分类列表
            courseClassData: courseClass,
            // 分类列表详情数据
            courseData: courseData,
            // 轮播图列表
            enterpriseBannerList:[
                {
                    id:"1",
                    title:"企业补贴培训方案",
                    tips:"立即申请合作",
                    img:'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/enterpriseBanner.png',
                },
            ],
            categoryList:[
                {
                    "children":
                    [
                        {
                            "name":"基础知识","id":4
                        },{
                            "name":"服务与技能","id":5
                        },{
                            "name":"营销与管理","id":6
                        }

                    ],"name":"酒店行业","id":1
                },{
                    "children":
                    [
                        {
                            "name":"基础知识","id":7
                        },{
                            "name":"服务与技能","id":8
                        },{
                            "name":"营销与管理","id":9
                        },{
                            "name":"营销与管理","id":9
                        }

                    ],"name":"餐饮行业","id":2
                },{
                    "children":
                    [
                        {
                            "name":"特制课程","id":10
                        }

                    ],"name":"企业定制","id":3
                }

            ],
           
             // 分类列表
            firstCategoryList:[
                {   
                    idx:1,
                    title:"酒店行业",
                    name:"酒店行业",
                    mark:"管理 酒店 餐饮",
                    color:"linear-gradient(135deg, #F3D0A8 0%, #D0A365 100%)",
                    img:'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/schoolCate.png',
                    // tabList:['全部','酒店运营','空间设计','服务管理','经营管理']
                    tabList:[
                        {
                            name:"全部",
                            color:true,
                        },
                        {
                            name:"基础知识",
                            color:false,
                        },
                        {
                            name:"服务与技能",
                            color:false,
                        },
                        {
                            name:"营销与管理",
                            color:false,
                        },
                    ],
                    conList:[]
                },
                {   
                    idx:2,
                    title:"餐饮行业",
                    name:"餐饮行业",
                    mark:"餐厅服务 管理服务",
                    color:"linear-gradient(135deg, #FFAC99 0%, #F85F2B 100%)",
                    img:'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/schoolCate2.png',
                    tabList:[
                        {
                            name:"全部",
                            color:true,
                        },
                        {
                            name:"基础知识",
                            color:false,
                        },
                        {
                            name:"服务与技能",
                            color:false,
                        },
                        {
                            name:"营销与管理",
                            color:false,
                        },
                    ],
                    conList:[]
                },
                {   
                    idx:3,
                    title:"企业定制",
                    name:"企业定制",
                    mark:"特制课程 创新",
                    color:"linear-gradient(135deg, #57D2FF 0%, #207E9C 100%)",
                    img:'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/schoolCate3.png',
                    // tabList:['全部','定制课程','空间设计','服务管理','经营管理']
                    tabList:[
                        {
                            name:"全部",
                            color:true,
                        },
                        {
                            name:"特制课程",
                            color:false,
                        },
                    ],
                    conList:[]
                },
                {   
                    idx:4,
                    title:"通识课程",
                    name:"通识课程",
                    mark:"高效办公 营销与管理",
                    color:"linear-gradient(135deg, #91AFF0 0%, #1352C8 100%)",
                    img:'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/nx/video/4fehb2022011717134138.png',
                    // tabList:['全部','定制课程','空间设计','服务管理','经营管理']
                    tabList:[],
                    conList:[]
                }
            ],
             showMore:'hide',
            // 行业动态列表
            dynamicList:[
                // https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/
                {
                    img:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/enterpriseDynamic.jpeg",
                    title:"”十四五”时期，如何提高职业技能培训质量？",
                    district:"开展大规模职业技能培训是提升劳动者就业创业能力、缓解结构性就业矛盾、是推动高质量发展的重要支撑日前,人社部等四部门印发《“十四五”职业技能培训规划》十四五”时期如何提高职业技能培训质量?带你一图看懂",
                    link:"http://www.mohrss.gov.cn/SYrlzyhshbzb/dongtaixinwen/buneiyaowen/rsxw/202112/t20211230_431882.html"
                },
                {   
                    img:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/enterpriseDynamic2.jpeg",
                    title:"嘉定区2021年落实企业稳岗扩岗专项支持计划实施以工代训补贴公示",
                    district:"根据《上海市人力资源和社会保障局等四部门关于落实企业稳岗扩岗专项支持计划实施以工代训补贴的通知》（沪人社规〔2020〕17号）规定，嘉定区对2021年落实企业稳岗扩岗专项支持计划实施以工代训补贴经费核拨进行了审核，现将核拨情况公示，公示时间：12月27日--31日。如有异议，向嘉定区人力资源和社会保障局反馈意见。",
                    link:"http://finance.sina.com.cn/jjxw/2021-12-28/doc-ikyakumx6917366.shtml"
                },
                {   
                    img:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/enterpriseDynamic3.jpeg",
                    title:"通州区人社局：“援企稳岗”政策找到人“以工代训”补贴送上门",
                    district:"为进一步减轻新冠疫情对企业的影响，降低企业运营负担，加大对新吸纳劳动者企业的支持，2021年， 区人社局按照国家和省市部署要求，根据《关于印发<南通市通州区职业技能提升行动专账资金管理使用细则>的通知》文件精神，坚持将以工代训作为职业技能提升行动的重要组成部分，加强组织领导，推动贯彻实施，对2021年度1-6月 有新录用员工并为其缴纳一个月以上社保的符合条件",
                    link:"http://www.tongzhou.gov.cn/tzqrmzf/bmdt/content/364764a9-6330-47b3-b825-8fa9b3325283.html"
                },
                {   
                    img:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/enterpriseDynamic4.jpeg",
                    title:"福耀科技大学曝新进展！预计2-3年内竣工！",
                    district:"12月4日，在中国教育三十人论坛第八届年会上，福耀集团创始人、董事长、河仁慈善基金会理事长曹德旺对外透露了福耀科技大学的最新建设进展。曹德旺对话中国陶行知研究会会长、新教育实验发起人",
                    link:"https://m.sohu.com/a/505824790_187768"
                },
            ],
            iframeList: [
                {
                    title: "酒店餐饮企业内训",
                    text:"完整而全面的课程架构搭建，满足酒店餐饮企业从上至下各类职能的培训需求。",
                    src_mp4:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/video/2D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/1.mp4",
                    img_src: "https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/img/2D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/1.png"
                },
                {
                    title: "酒店餐饮企业内训",
                    text:"完整而全面的课程架构搭建，满足酒店餐饮企业从上至下各类职能的培训需求。",
                    src_mp4:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/video/2D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/2.mp4",
                    img_src: "https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/img/2D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/2.png"
                },
                {
                    title: "酒店餐饮企业内训",
                    text:"完整而全面的课程架构搭建，满足酒店餐饮企业从上至下各类职能的培训需求。",
                    src_mp4:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/video/2D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/3.mp4",
                    img_src: "https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/img/2D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/3.png"
                },
                {
                    title: "酒店餐饮企业内训",
                    text:"完整而全面的课程架构搭建，满足酒店餐饮企业从上至下各类职能的培训需求。",
                    src_mp4:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/video/2D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/4.mp4",
                    img_src: "https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/img/2D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/4.png"
                },
                {
                    title: "企业定制培训课程",
                    text:"助力多家知名餐饮企业定制培训课程，以精美的视频制作呈现备受各界好评。",
                    src_mp4:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/video/2D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/5.mp4",
                    img_src: "https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/img/2D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/5.png"
                },
                {
                    title: "企业定制培训课程",
                    text:"助力多家知名餐饮企业定制培训课程，以精美的视频制作呈现备受各界好评。",
                    src_mp4:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/video/2D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/6.mp4",
                    img_src: "https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/img/2D%29%E7%A4%BA%E4%BE%8B%E8%A7%86%E9%A2%91/6.png"
                },
            ],
            iframeForm:{
                firstTitle:"专业定制内容",
                secondTitle:"助力各类行业企业人才培养"
            },
            // 预约咨询
            appointmentVisiable:false,
            appointmentForm:{},
            appointmentTitle:""
        }
    },
    created(){
        this.initData()
    },
    methods:{
        initData(){
            this.getSecondCategoryList()
            this.getCourseList(0)
            this.getCourseList(1)
            this.getCourseList(2)
            this.getCourseList(3)
        },

         // 获取家政服务类课程
        getCourseList(index){
            let list = JSON.parse(JSON.stringify(this.firstCategoryList[index].tabList))
            list.splice(0,1);
            let listChildrenIds = [];
            list.forEach((val)=>{
                val.children.map(item=>{
                    listChildrenIds.push(item.id)
                })
            })
            this.courseData.forEach(item => {
                listChildrenIds.forEach(val=>{
                    if (val == item.id) {
                        this.firstCategoryList[index].conList.push(item)
                    }
                })
            })
        },
        // 获取二级分类
        getSecondCategoryList(){
            this.firstCategoryList.forEach((val,idx)=>{
                this.firstCategoryList[idx].tabList = [];
                this.courseClassData.forEach(item=>{
                    if(val.name == item.name){
                        this.firstCategoryList[idx].tabList = JSON.parse(JSON.stringify(item.children))
                    }
                })
            })
            this.firstCategoryList.forEach((val)=>{
                val.tabList.forEach((item)=>{
                    item.color = false
                })
                val.tabList.unshift({name:"全部",color:true})
            })
        },
        // 查看课程中心
        showMoreCourse(){
            // this.$router.push('/courseCenter')
            this.$router.push(
                    {
                        path:"/courseCenter",
                        query:{
                            firstIndex:3,
                            name:'企业定制'
                        }
                    }
                )
        },
         // 预约咨询
        appointenting(){
            this.appointmentForm = {};
            this.appointmentTitle = '企业内训预约咨询';
            this.appointmentForm ={
                name:"",
                scale:"",
                major:"",
                city:[],
                contact:'',
                phone:'',
                type:"company"
            }
            this.appointmentVisiable = true;
        },
        // 关闭弹窗
        clsoeDialog(){
            this.appointmentVisiable = false
        },
        // 合作院校更多
        dynamicMore(){

        },
    }
}
</script>

<style lang='scss' scoped>
    ::v-deep{
        .el-carousel__container{
            height: 680px;
        }
    }
@media screen and (min-width: 1560px){
    .trainingEnterprise{
        // width: 100%;
        // position: relative;
        width: 100%;
        min-width: 1200px;
        min-height: 800px;
        box-sizing: border-box;
        position: relative;
        background: #FAFAFA;
}
    // banner图片
    .top_banner{
        position: relative;
    }
    .el-carousel__item {
        img{
            width: 100%;
            height: 680px;
        }
    }
    // 轮播图名称
    .banner_title{
        position:absolute;
        top: 30%;
        left:16%;
        .title_text{
            font-size: 50px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color:rgba(6, 135, 252, 1)
        }
        // .title_line{
        //     width: 32px;
        //     height: 3px;
        //     background: #FFFFFF;
        //     margin: 37px 0px;
        // }
        .title_tips{
            font-size: 24px;
            font-family: SourceHanSansCN-Normal, SourceHanSansCN;
            font-weight: 400;
            color: rgba(6, 135, 252, 1);
            line-height: 36px;
        }
    }
    // banner查看详情 
    .show_detail{
        position: absolute;
        bottom: 40%;
        left:16%;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 182px;
        height: 56px;
        background: linear-gradient(90deg, #0084FC 0%, #369EFC 100%);
        border-radius: 4px;
        font-size: 24px;
        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
        font-weight: 400;
        cursor: pointer;
        color: #FFFFFF;
        z-index: 1000000;
        img{
            width: 22px;
            height: 22px;
            margin-left:16px;
        }
    }
    // 国家职业预约咨询
    .appointment{
        margin:20px 0px;
        padding: 0 16%;
        height: 76px;
        line-height: 76px;
        background-image: url('../../assets/images/school/school_appointment_banner.png');
        background-size: 100% 100%;
        .b_title{
            font-weight: bold;
        }
        .occupation{
            margin-left: 10px;
        }
        .right{
            width: 160px;
            height: 46px;
            border-radius: 23px;
            border: 1px solid #333333;
            cursor: pointer;
            img{
                width: 26px;
                height: 26px;
                margin-right:10px;
            }
        }
    }
     //合作院校
    .partnerSchool{
        background: #FFFFFF;
        padding:0 16%;
        .dy_title{
            padding-top: 30px;
            width: 100%;
            margin-left:10px;
            margin-bottom:10px;

        }
        .titte_before{
            font-size: 26px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #333333;
            line-height: 39px;
        }
        .titte_before::before{
            content: '';
            display: inline-block;
            width: 4px;
            height: 23px;
            background: #EA5A41;
            position: relative;
            top:2px;
            left:-10px
        }
        .dy_more{
            font-size: 14px;
            font-family: SourceHanSansCN-Normal, SourceHanSansCN;
            font-weight: 400;
            color: #999999;
            margin-right: 20px;
            img{
                width: 4px;
                height: 8px;
            }
        }
    }
    // 合作院校图片
    .partnerSchool_img{
        padding:47px 0px 52px;
        img{
            width: 100%;
        }
    }
}

@media screen and (max-width: 1560px){
    .trainingEnterprise{
        width: 100%;
        min-width: 1200px;
        box-sizing: border-box;
        position: relative;
        background: #FAFAFA;
}
    // banner图片
    .top_banner{
        position: relative;
    }
    .el-carousel__item {
        img{
            width: 100%;
            height: 680px;
        }
    }
    // 轮播图名称
    .banner_title{
        position:absolute;
        top: 30%;
        left:10%;
        .title_text{
            font-size: 50px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color:rgba(6, 135, 252, 1)
        }
        // .title_line{
        //     width: 32px;
        //     height: 3px;
        //     background: #FFFFFF;
        //     margin: 37px 0px;
        // }
        .title_tips{
            font-size: 24px;
            font-family: SourceHanSansCN-Normal, SourceHanSansCN;
            font-weight: 400;
            color: rgba(6, 135, 252, 1);
            line-height: 36px;
        }
    }
    // banner查看详情 
    .show_detail{
        position: absolute;
        bottom: 40%;
        left:10%;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 182px;
        height: 56px;
        background: linear-gradient(90deg, #0084FC 0%, #369EFC 100%);
        border-radius: 4px;
        font-size: 24px;
        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
        font-weight: 400;
        cursor: pointer;
        color: #FFFFFF;
        z-index: 1000000;
        img{
            width: 22px;
            height: 22px;
            margin-left:16px;
        }
    }
    // 国家职业预约咨询
    .appointment{
        margin:20px 0px;
        padding: 0 10%;
        height: 76px;
        line-height: 76px;
        background-image: url('../../assets/images/school/school_appointment_banner.png');
        background-size: 100% 100%;
        .b_title{
            font-weight: bold;
        }
        .occupation{
            margin-left: 10px;
        }
        .right{
            width: 160px;
            height: 46px;
            border-radius: 23px;
            border: 1px solid #333333;
            cursor: pointer;
            img{
                width: 26px;
                height: 26px;
                margin-right:10px;
            }
        }
    }
     //合作院校
    .partnerSchool{
        background: #FFFFFF;
        padding:0 10%;
        .dy_title{
            padding-top: 30px;
            width: 100%;
            margin-left:10px;
            margin-bottom:10px;

        }
        .titte_before{
            font-size: 26px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #333333;
            line-height: 39px;
        }
        .titte_before::before{
            content: '';
            display: inline-block;
            width: 4px;
            height: 23px;
            background: #EA5A41;
            position: relative;
            top:2px;
            left:-10px
        }
        .dy_more{
            font-size: 14px;
            font-family: SourceHanSansCN-Normal, SourceHanSansCN;
            font-weight: 400;
            color: #999999;
            margin-right: 20px;
            img{
                width: 4px;
                height: 8px;
            }
        }
    }
    // 合作院校图片
    .partnerSchool_img{
        padding:47px 0px 52px;
        img{
            width: 100%;
        }
    }
}

</style>